.DictionaryContainer {
  width: 97% !important;
  padding: 25px;
  border-radius: 0px 4px;
  /* background: rgba(255, 255, 255, 0.1); */
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* margin: auto auto; */
  margin-left: -2% !important;
  /* margin-top: 8% !important; */
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;
  max-height: calc(100vh - 200px);
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  /* min-height: 500px; */
}
.DictionaryContainer h1 {
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 992px) {
  .DictionaryContainer {
    max-height: 100%;
    margin-left: 2% !important;
  }
}

.DictionaryContainer::-webkit-scrollbar {
  width: 15px;
}

.DictionaryContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.DictionaryContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.RowContainer {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 15px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}

.RowContainer h1 {
  color: #648467;
  cursor: pointer;
}

.RowContainer h1:hover {
  color: #429e4b;
}

.RowContainer span {
  color: #648467;
}
.RowContainer h1 {
  text-align: justify;
}
.RowContainer p {
  text-align: justify;
}
.GalleryContainer {
  margin-top: 10%;
  margin-left: -10%;
  margin-right: 10%;
  text-align: center;
  color: #648467;
}

@media (max-width: 600px) {
  .DictionaryMainContainer {
    min-height: 100vh;
  }
  .RowContainer {
    margin: 5px;

    padding-bottom: 30px;
  }
  .DictionaryContainer {
    width: 90% !important;
    padding: 10px;
    border-radius: 0px 4px;
    /* background: rgba(255, 255, 255, 0.1); */
    background-color: #dff6c8;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    /* margin: auto auto; */
    margin-left: 5% !important;
    max-height: 100%;

    /* margin-top: 8% !important; */
    /* min-height: 500px; */
  }
  .DictionaryContainer div {
    border-bottom: 2px solid red;
    margin: 10px 0px;
  }
}
