.DictionaryContainer {
  /* background: #81bd87; */
  width: 95% !important;
  padding: 20px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin: auto auto;
  margin-left: -3% !important;
  margin-top: 1% !important;
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;
  max-height: 500px;
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  min-height: 550px;
  background-color: #dff6c8;
}

.DictionaryContainer h1 {
  color: #648467;
  text-align: center;
  /* cursor: pointer; */
}

.CareersContent {
  /* border: 2px solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  margin-left: 10px !important;
}

.CareersInfo {
  /* border: 2px solid red; */
  height: 100%;
  width: 40%;
  font-size: 13px;
  color: #333;
  text-align: center;
  padding: 20px 40px;
  margin-left: -5%;
  text-align: left;
}

.CareersInfo h4 {
  margin-top: 10px;
}

.CareersInfo h6 {
  color: rgb(122, 122, 122);
  width: 80%;
}
.CareersInfo img {
  width: 100%;
  margin: 30%;
}
.CareersForm {
  /* border: 2px solid red; */
  height: 100%;
  width: 50%;
  margin-top: 10px;
}

.CareersForm form div {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin: auto;
  margin-top: 5px;
}

.CareersForm form div input {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
}

.CareersForm form div button {
  border-radius: 5px;
  width: 50%;
  margin: auto;
  margin-top: 20px;
  background-color: #648467;
  color: rgb(0, 0, 0);
  padding: 5px;
  border: none;
}

.CareersForm form div button:hover {
  color: white;
  background-color: #135819;
}
.AddresContainer {
  width: 40%;
  margin-top: 60px;
}
.ButtonContainer {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 992px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    max-height: 100%;

    /* margin-left: -10% !important; */
    margin-top: 4% !important;
    min-height: 100vh;
  }
  .CareersContent {
    display: flex;
    flex-direction: column;
  }
  .CareersForm {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    margin: 10px 0px;
  }
  .CareersForm form div {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    margin-top: 5px;
  }
  .CareersInfo {
    height: 100%;
    width: 100%;
    font-size: 13px;
    padding: 20px 5px;
    margin-left: 0%;
    text-align: center;
  }

  .CareersInfo h6 {
    color: rgb(122, 122, 122);
    width: 100%;
  }
  .CareersInfo img {
    width: 100%;
    height: 250px;
    margin-left: 100px;
  }
  .AddresContainer {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    width: 100% !important;

    margin-left: 0% !important;
    margin-bottom: 40px;
  }
  .CareersInfo img {
    width: 100%;
    margin-left: 0px;
  }
  .CareersInfo {
    height: fit-content !important;
  }
}
