.DictionaryContainer {
  /* background: #81bd87; */
  /* width: 80% !important; */
  width: 71% !important;
  padding: 20px;
  border-radius: 4px;
  /* background: rgba(255, 255, 255, 0.05); */
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* margin: auto auto !important; */
  margin-left: 24%;
  margin-top: 2% !important;
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;
  max-height: 350px;
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}
/* @media (min-width: 1500px) {
  .DictionaryContainer {
    height: fit-content !important;
    max-height: 440px;

    border: 1px solid red;
  }
} */
@media (min-width: 1400px) {
  .DictionaryContainer {
    height: 100%;
    max-height: calc(90vh - 200px);
  }
}
.DictionaryContainer::-webkit-scrollbar {
  width: 15px;
}

.DictionaryContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.DictionaryContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.AlphaContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.AlphaContainer b {
  width: 75px;
  padding: 5px 15px;
  color: rgb(10, 156, 5);
  font-size: 26px;
}

.AlphaContainer ul {
  width: calc(100% - 75px);
  padding: 0 15px;
  flex-direction: column;
  margin-top: 7px;
  /* flex-direction: column; */
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.AlphaContainer ul li {
  line-height: 24px;
  /* width: fit-content; */
  padding: 5px 15px;
  /* float: left; */
  text-align: left;
  color: #275207;
  height: 200px;
  border-bottom: solid 2px #ccc;
  /* padding: 3.5% 0; */
}
.AlphaContainer ul li:before {
  /* content: "•"; */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.AlphaContainer ul li a,
.AlphaContainer ul li span {
  color: black;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
/* .AlphaContainer ul li span:hover
{
  color: rgb(4, 0, 255);
} */

.AlphaContainer ul li a:hover {
  color: rgb(56, 56, 56);
}

.ProgressBar {
  position: absolute;
  height: 5px;
  /* border: 2px solid; */
  background-color: rgb(110, 110, 110);
  width: 51%;
  top: 274px;
  left: 360px;
  z-index: 10;
}

.Progress {
  position: relative;
  width: 0%;
  height: 100%;
  background-color: #a4d62c;
}

.ProductDataContainer {
  /* background: #81bd87; */
  /* width: 80% !important; */
  width: 73% !important;
  padding: 20px;
  border-radius: 4px;
  /* background: rgba(255, 255, 255, 0.05); */
  /* background-color: #dff6c8; */
  background-color: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* margin: auto auto !important; */
  margin-left: 22%;
  margin-top: 2% !important;
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;
  height: 70vh !important;
  max-height: 350px;
  flex-direction: column;
  overflow-y: auto;
  text-align: center;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}

@media (min-width: 1400px) {
  .ProductDataContainer {
    height: 100%;
    max-height: calc(90vh - 200px) !important;
  }
}
/* .ProductDataContainer::-webkit-scrollbar {
  width: 5px;
}

.ProductDataContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.ProductDataContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
} */

.ProductDataContainer p {
  width: 80%;
  margin: 10px auto;
  text-align: center;
}

.ProductDataContainer img {
  width: 100%;
  margin: 20px auto;
}

.ProductDataContainer table {
  /* border: 2px solid; */
  margin-top: 70px;
  margin-bottom: 20px;
}

.ProductDataContainer thead {
  /* height: 100px; */
  /* text-align: ; */
  vertical-align: text-top;
}

.ProductDataContainer th {
  padding: 10px 5px;
  padding-bottom: 0px;
  border: 1px solid;
  font-size: 18px;
}

.ProductDataContainer th div {
  border-bottom: 2px solid;
  padding-bottom: 15px;
}

.ProductDataContainer th span {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid; */
  /* height: 50%; */
  text-align: center;
}

.ProductDataContainer th span span {
}

.ProductDataContainer th div td {
  padding-right: 10px;
}

.ProductDataContainer tbody td {
  border: 1px solid;
  padding: 5px;
  font-size: 15px;
}

.ProductDataContainer tbody tr td div {
  display: flex;
}

.ProductDataContainer tbody tr td div img {
  width: 40px;
  cursor: pointer;
}

.ContentContainer h2 {
  margin-top: 10px !important;
}
.ContentContainer h1 {
  margin-top: 10px !important;
}
.ContentContainer div {
  margin-top: 15px !important;
}
.ContentContainerDesc {
  margin-left: 8%;
}
.ContentContainerDesc svg {
  margin-right: 10px;
  color: #275207;
}
.ContentContainer h2 {
  text-align: center;
  margin-top: -32px;
  font-size: 25px;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
  height: fit-content;
}
.ContentContainer h1 {
  text-align: center;
  margin-top: -32px;
  font-size: 25px;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
  height: fit-content;
}
.ContentContainer h5 {
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  font-weight: 100;
  color: rgb(46, 5, 5);
  font-weight: bold;
}
@media (min-width: 1000px) and (max-width: 1250px) {
  .DictionaryContainer {
    /* 
    width: 71% !important;
    margin: 0 auto;
    margin-left: 25%; */
  }
}
@media (max-width: 992px) {
  /* .ContentContainerDesc {
    margin-left: 0%;
  }
  .ContentContainer h2 {
    margin-top: 40px !important;
  } */
  #productMobileContainer {
    /* 
    height: 100% !important;
    min-height: 100% !important; */
    height: 100%;
    height: 100vh;
  }
  .DictionaryContainer {
    width: 90% !important;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    min-height: 100% !important;
    overflow-y: auto;
    padding: 20px 10px;
    margin: 0 auto;
    /* margin: 100px 0px !important ; */
  }
  /* .ProductDataContainer {
    width: 90% !important;
    
    min-height: 100vh !important;
  } */
  .ProductDataContainer {
    width: 90% !important;
    overflow-y: auto;
    height: 100vh !important;
  }
  #productMobileContainers {
    height: 100vh;
  }
  .ProductDataContainer img {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .productMobileContainer {
  }
  .ContentContainer h5 {
    text-align: center;
    width: 95%;
  }
  .ContentContainer h5 span {
  }
  .ContentContainer {
    /* border: 2px solid red; */
  }
  .ContentContainerDesc {
  }
  .ProductDataContainer {
    background: #81bd87;
    width: 90% !important;
    padding: 20px;
    border-radius: 4px;
    background-color: #f2fde6;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    margin: 20px;
    /* margin: auto auto !important; */
    /* margin-left: 0%;
    margin-top: 2% !important; */
    /* padding-bottom: 1px !important;
    display: flex;
    height: fit-content !important; */
    /* height: fit-content !important;
    height: 70vh !important; */
    /* flex-direction: column;
    overflow-y: auto;
    text-align: center;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px); */
  }
  .ProductDataContainer table {
    width: 200vh;
  }
}

.hr_break_line {
  border: 2px solid rgb(0, 37, 0);
}
.parentProductImageStyles {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  height: fit-content !important;
  align-self: flex-start;
}
.parentProductImageStylesHand {
  /* flex: 1; */
  margin-right: 10px;
}
.parentProductImageStylesTitle svg {
  color: green;
  margin-right: 5px;
}
@media (max-width: 600px) {
  .parentProductImageStylesHand {
    /* flex: 1; */
    display: none;
  }
}
@media (min-width: 600px) {
  .parentProductImageStylesTitle svg {
    /* flex: 1; */
    display: none;
  }
}
.parentProductImageStylesTitle {
  flex: 5;
  color: black;
  font-weight: bold;
}
.parentProductImageStylesTitle:hover {
  cursor: pointer;
}
.parentProductImageStylesImg img:hover {
  cursor: pointer;
}

.parentProductImageStylesImg {
  flex: 2;
}
/* .parentProductImageStylesTitle {
  flex: 2;
}
.parentProductImageStylesImg {
  flex: 1;
} */
.parentProductImageStylesImg {
  height: 100%;
}
.parentProductImageStylesImg img {
  width: 40%;
}

@media (max-width: 600px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    /* width: 80% !important; */
    width: 90% !important;
    margin-left: 5%;
    padding: 20px;
    margin-top: 50px !important;
    padding-bottom: 1px !important;
    display: flex;
    height: fit-content !important;
    height: 100vh !important;
  }
  .ContentContainer h2 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 10px;
    font-size: 25px;
    color: rgb(0, 0, 0);
    margin-bottom: 0px;
    height: fit-content;
  }
  .ContentContainer h1 {
    text-align: center;
    margin-top: -12px;
    margin-bottom: 10px;
    font-size: 25px;
    color: rgb(0, 0, 0);
    margin-bottom: 0px;
    height: fit-content;
  }
  .ContentContainerDesc {
    margin-left: 0%;
  }
  .AlphaContainer ul {
    width: 100%;
    padding: 0 15px;
  }
  .AlphaContainer b {
    width: 0px;
    padding: 0px;
    color: rgb(10, 156, 5);
    font-size: 0px;
  }
  .parentProductImageStyles {
  }
  .parentProductImageStyles {
    flex-direction: column;
  }
  .parentProductImageStyles div {
    flex-basis: 100%;
  }

  .ContentContainer div {
    margin-top: 30px;
  }
  .AlphaContainer ul {
    padding: 0px;
  }

  .parentProductImageStylesTitle {
    flex: 3;
    color: black;
    font-weight: bold;
  }
  .parentProductImageStylesImg {
    text-align: center;
  }
  .parentProductImageStylesImg img {
    text-align: center;
  }

  .parentProductImageStyles hr {
    border: 1px solid black;
    width: 100%;
    margin-top: 30px;
  }
}
/* .AlphaContainer ul {
  width: calc(100% - 75px);
  padding: 0 15px;
  flex-direction: column;
  margin-top: 7px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
} */
