.DictionaryContainer {
  /* background: #81bd87; */
  /* width: 100% !important; */

  width: 97% !important;
  padding: 25px;
  border-radius: 0px 4px;
  /* background: rgba(255, 255, 255, 0.1); */
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* margin: auto auto; */
  margin-left: -2% !important;
  /* margin-top: 8% !important; */
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;
  max-height: calc(100vh - 200px);
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  /* min-height: 500px; */
  margin-top: 2% !important;
}
@media (min-width: 1500px) {
  .DictionaryContainer {
    height: fit-content !important;
  }
}
@media (max-width: 992px) {
  .DictionaryContainer {
    max-height: 100%;
    margin-left: 2% !important;
  }
}

.DictionaryContainer::-webkit-scrollbar {
  width: 15px;
}

.DictionaryContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.DictionaryContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.RowContainer {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 15px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}

.RowContainer h1 {
  color: #648467;
  cursor: pointer;
}

.RowContainer h1:hover {
  color: #429e4b;
}

.RowContainer span {
  color: #648467;
}
/* .RowContainer img {
  width: 30%;
} */

.DictionaryMainContainer {
  margin-top: 10px;
}
.DictionaryMainContainer h1 {
  text-align: center;
  font-size: 2rem;
}
.RowContainer h3 {
  text-align: center;
  margin: 10px 0px;
}
.RowContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  width: 250px;
}
.Description {
  text-align: center;
  margin: 15px 0px;
}
.Description ul {
  display: inline-block;
  text-align: left;
}
.GalleryContainer {
  margin-top: 10%;
  margin-left: -10%;
  margin-right: 10%;
  text-align: center;
  color: #648467;
}
.GalleryContainers img {
  width: 100% !important;
}
.GalleryContainers div {
  font-size: 20px;
}
.GalleryContainers p {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
}
@media (max-width: 600px) {
  .DictionaryMainContainer {
    margin: 30px 0px;
  }
  .GalleryContainers {
    text-align: center;
  }
  .GalleryContainers ul {
    margin-top: 3%;
    display: inline-block;
    text-align: left;
  }
  .DictionaryContainer {
    width: 100% !important;
    padding: 25px;
    margin-left: 0% !important;
    margin-top: 20px;
  }
}
