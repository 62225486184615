.DictionaryMainContainer {
  margin-top: 2% !important;
  margin-left: -3%;
}
.DictionaryMainContainer h1 {
  text-align: center;
  font-size: 2rem;
}
.DictionaryContainer {
  /* background: #81bd87; */
  /* width: 90% !important; */
  width: 95% !important;
  /* padding:20px; */
  padding: 0px 20px;
  border-radius: 4px;
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  /* margin: auto auto; */
  /* margin-left: -5% !important; */
  /* margin-top: 8% !important; */
  padding-bottom: 1px !important;
  display: flex;
  height: fit-content !important;

  max-height: calc(100vh - 200px);
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  background-color: #dff6c8;
  /* min-height: 500px; */
}
@media (min-width: 1500px) {
  .DictionaryContainer {
    height: 100% !important;
    max-height: calc(95vh - 140px);
  }
}
@media (max-width: 992px) {
  .DictionaryContainer {
    max-height: 100%;
  }
}
.DictionaryContainer::-webkit-scrollbar {
  width: 15px;
}

.DictionaryContainer::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

.DictionaryContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
}

.RowContainer {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 15px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}

.RowContainer h2 {
  color: #648467;
  cursor: pointer;
}

.RowContainer h2:hover {
  color: #429e4b;
}

/* .RowContainer span {
  color: #000000;
  font-size: 15px;
} */

.RowContainer h3 {
  text-align: center;
}
.RowContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  width: 250px;
}
.RowContainer p {
  color: #000000;
  font-size: 15px;
  padding: 20px;
  text-align: center;
}
.NewsEventsContainer {
  background: #f2fde6;
}
.NewsEventsContainer img {
  width: 85% !important;
  /* height: 250px !important; */
  height: auto;
}
.NewsEventsContainer {
  padding: 2%;
}
.NewsContainer {
  display: flex;
}
.NewsEventsContainer p {
  padding: 2% 1%;
}
@media (max-width: 600px) {
  .NewsEventsContainer p {
    text-align: center;
  }
  .NewsEventsContainer h3 {
    text-align: center;
    padding-top: 3%;
  }
  .DictionaryContainer {
    width: 100% !important;
  }
  .DictionaryMainContainer {
    margin-left: 0%;
    padding: 5px;
    padding-bottom: 30px;
  }
  .NewsEventsContainer img {
    margin: 0 auto 20px;
    left: 7.5%;
    position: relative;
  }
}
