.home-slilder-container {
  position: absolute;
  z-index: 9999 !important;
  width: 20vw;
  margin-left: 2vw;
  margin-top: 7vw;
}
.home-slilder-container .slick-arrow {
  display: none !important;
}
.home-slilder-container .slick-dots {
  margin-bottom: 33vh;
}
/* .home-slilder-container .slick-dots {
  position: fixed;
  margin: 30px;
}

.home-slilder-item h3 {
  margin-top: 10px;
} */
