.DictionaryContainer {
  /* background: #81bd87; */
  width: 71% !important;
  padding: 0px 20px;
  border-radius: 4px;
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin-left: 24% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 1px !important;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  height: 75vh !important;
}

@media (max-width: 600px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    width: 71% !important;
    padding: 0px 20px;
    border-radius: 4px;
    background: #f2fde6;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    margin-left: 24% !important;
    display: flex;
    flex-direction: row;
    height: 1000px;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    height: 150vh !important;
  }
}

.catologue-image {
  width: 130px;
  /* max-height: 320px; */
  height: auto;
  background-size: contain;
}
@media (max-width: 600px) {
  .catologue-image {
    width: 230px;
  }
}
.DictionaryMainContainer h2 {
  color: #000000;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  margin-left: 53%;
  /* cursor: pointer; */
}
.DictionaryMainContainer h6 {
  color: #000000;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  /* margin-left: 15%; */
  /* cursor: pointer; */
}
.catologue-container {
  text-align: center;
  width: 300px;
}

@media (max-width: 600px) {
  .catologue-container {
    position: none;
    padding: none;
    width: 100%;
    text-align: center;
  }
}
.catologue-container {
  position: relative;
  padding: 10px;
}

.CareersContent {
  /* border: 2px solid; */
  display: flex;
}

.CareersInfo {
  /* border: 2px solid red; */
  height: 100%;
  width: 40%;
  font-size: 25px;
  /* margin-top: 7%; */
  color: #333;
  text-align: center;
  padding: 20px 40px;
  margin-left: 5%;
}

.CareersForm {
  /* border: 2px solid red; */
  height: 100%;
  width: 50%;
  margin-top: 1%;
}

.CareersForm form div {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin: auto;
  margin-top: 5px;
}

.CareersForm form div input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
}

.CareersForm form div label span {
  font-size: 12px;
  color: rgb(255, 102, 102);
}

.CareersForm form div button {
  border-radius: 5px;
  width: 50%;
  margin: auto;
  margin-top: 20px;
  background-color: #648467;
  color: rgb(0, 0, 0);
  padding: 5px;
  border: none;
}

.CareersForm form div button:hover {
  color: white;
  background-color: #135819;
}

@media (max-width: 992px) {
  .DictionaryContainer {
    width: 90% !important;
    margin: auto auto;
    max-height: 100%;
    flex-direction: none;
  }

  .CareersContent {
    flex-direction: column;
    max-height: 100%;
  }
  .CareersInfo {
    height: 100%;
    width: 100%;
    font-size: 13px;
    color: #333;
    text-align: center;
    padding: 15px;
    margin-left: -2%;
  }
  .CareersForm {
    height: 100%;
    width: 100%;
    margin: 20px 0px;
    padding: 0px;
  }
  .CareersForm form div {
    width: 75%;
  }
}

@media (max-width: 600px) {
  .DictionaryMainContainer {
    height: 100vh;
  }

  .DictionaryContainer {
    width: 90% !important;
    padding: 20px;
    border-radius: 4px;
    background-color: #dff6c8;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    margin-left: 5% !important;
    margin-top: 50px;
    height: 600px;
  }

  @media (max-width: 600px) {
    .DictionaryContainer {
      height: 2500px !important;
    }
  }
  .DictionaryMainContainer h2 {
    color: #000000;
    margin-top: 6% !important;
    margin-bottom: 0% !important;
    margin-left: 0%;
    text-align: center;
  }
}
