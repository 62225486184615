.DictionaryContainer {
  /* background: #81bd87; */
  width: 95% !important;
  padding: 20px;
  border-radius: 4px;
  /* background: #dff6c8; */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin-top: 1% !important;
  padding-bottom: 1px !important;
  display: flex;
  height: calc(100vh - 250px);
  flex-direction: column;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  margin-left: -6%;
  background: #f2fde6;
}
.customTitleh1 h1 {
  margin-left: 38%;
  font-size: 32px;
  margin-top: 3%;
}

.DictionaryContainer h1 {
  color: #648467;
  text-align: center;
}
.DictionaryContainer img {
  width: 80%;
  padding-left: 15%;
}
.DictionaryContainer h5 {
  text-align: center;
}
.DictionaryContainer p {
  text-align: center;
}

@media (max-width: 600px) {
  .DictionaryContainer {
    width: 100% !important;
    padding: 20px;
    margin-left: 0%;
  }
  .customTitleh1 h1 {
    margin-left: 0%;
    text-align: center;
  }
  .customTitleh1 {
    height: fit-content;
    margin-top: 50px !important;
  }
}
