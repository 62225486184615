.social_media_main_wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social_media_icons_text {
  display: flex;
  align-items: center;
}

.social_media_icons_text > p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.social_media_wrapper {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  gap: 15px;
  padding-bottom: 5px;
  padding-left: 25px;
}

.social_media_wrapper svg {
  color: #275207;
  font-size: 17px;
}
.developed_text_new {
}
.developed_text_new h6 {
  font-size: 10px;
  margin-bottom: 0;
}
.developed_text_new a {
  margin-left: 3px;
  text-decoration: none;
  color: #275207;
}

.developed_text {
  position: absolute;
  bottom: 0;
  right: 10px;
  padding-bottom: 5px;
  font-size: 10px;
}
.developed_text h6 {
  font-size: 12px;
  margin-bottom: 0;
}
.developed_text a {
  margin-left: 3px;
  text-decoration: none;
  color: #275207;
}

@media (max-width: 1000px) {
  .social_media_main_wrapper {
    position: relative;

    background-color: #f2fde6;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    padding: 15px 0px;
  }

  .developed_text {
    position: relative;
  }
  .developed_text h6 {
    font-size: 18px;
  }
  .developed_text h6 {
    font-size: 12px;
    margin-bottom: 0;
  }

  .developed_text_new h6 {
    font-size: 18px;
  }
  .developed_text_new h6 {
    font-size: 12px;
    margin-bottom: 0;
  }
}
@media (max-width: 600px) {
  .social_media_main_wrapper {
    margin-top: 30px;
  }
}
@media (max-width: 350px) {
  .social_media_icons_text {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .developed_text {
    text-align: center;
  }
}

.event_title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.event_title h3:nth-child(2) {
  font-weight: bold;
}

.footer_social_fb svg {
  color: #3b5998;
}
.footer_social_ig svg {
  color: #c32aa3;
}
.footer_social_twitter svg {
  color: #1da1f2;
}
.footer_social_ldi svg {
  color: #0a66c2;
}
.footer_social_yt svg {
  color: #ff0000;
}
