* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.index-main-container {
  /* border: 2px solid red; */
}
/* */
/* .min_height {
  height: 80vh;
} */
.border1 {
  border: 14px solid red;
}
.border2 {
  border: 2px solid yellow;
}
a {
  text-decoration: none;
}
.needXhidden {
  overflow-x: hidden !important;
}

.banner {
  /* background-image: url(./assets/banner-n-2.jpg); */
  /* height: 106.5vh; */

  background-size: cover;
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}
.product-banner {
  /* background-image: url(./assets/rest-banner.png); */
  /* height: 106.5vh; */
  min-height: 150vh;
  background-size: cover;
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}
.rest-banner {
  /* background-image: url(./assets/rest-banner.png); */
  /* height: 106.5vh; */
  background-size: cover;
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}
#banner-1366 {
  /* Bg5.jpgbackground-image: url(./assets/sphoorti.png); */
  background-image: url(./assets/Bg5.jpg);

  background-size: cover;
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 992px) {
  .banner {
    /* background-image: url(./assets/sphoorti.png); */
    /* height: 106.5vh; */
    /* height: 100vh;
    background-size: 98% 98.9%;
    background-color: #ffffff;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative; */
  }
  .product-banner {
    background-image: url(./assets/Bg5.jpg);
    /* height: 106.5vh; */
    height: 100vh;
    background-size: cover;
    background-color: #ffffff;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
  }
  .rest-banner {
    background-image: url(./assets/Bg5.jpg);
    /* height: 106.5vh; */
    height: 100vh;
    background-size: 98% 98.9%;
    background-color: #ffffff;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
  }
  #banner-1366 {
    /* background-image: url(./assets/sphoorti.png); */
    background-image: url(./assets/Bg5.jpg);

    height: 100vh;
    background-size: 98% 98.9%;
    background-color: #ffffff;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
  }
  body {
    overflow: hidden;
  }
}

#logo {
  height: 60px;
}
#catalogue-btn {
  margin: 5px 0;
}
.catologue-container h2 {
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  /* margin-left:-90px; */
}
.catalog_new_tag {
  text-align: center;
  background-color: red;
  width: fit-content;
  margin: 10px auto 10px;
  min-height: 25px;
  color: #fff;
}
.catalog_new_tag_present {
  padding: 0px 10px;
}
.catologue-container img {
  /* transition: all 0.5s ease 0s; */
  margin: 0 auto;
}
.catologue-container img:hover {
  transform: scale(1.05);
}
#navbar-margin-container {
  margin-top: 16px;
}
.navbar {
  font-weight: bold;
  /* background-color: #f2fde6;
  height: 80px; */
}
.nav-item {
  font-size: 14px;
}

.nav-item a {
  font-size: 13px !important;
  font-weight: bold !important;
  color: #000 !important;
}

.nav-item div {
  font-size: 13px !important;
  font-weight: bold !important;
  color: #000 !important;
}

.nav-link-pop-up {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* background-color: #e4e6d8; */

  background: #f2fde6;
  z-index: 100;
  /* padding: 10px; */
}
.nav-link-sub-pop-up {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* background-color: #e4e6d8; */
  background: #f2fde6;
  z-index: 100;
  right: -150px;
  margin-top: -5px;
}

.nav-link-sub-pop-up span {
  border: 1px solid #d3d3d3;
  padding: 5px;
  cursor: pointer;
  width: 150px;
  height: 30px;
  font-weight: normal;
  font-size: 15px;
}
.cutom-nav-item {
  font-weight: 400;
  color: #000;
  text-decoration: none;
}
.cutom-nav-item:hover {
  color: #000;
  text-decoration: none;
}
.nav-link-pop-up span {
  border: 1px solid #d3d3d3;
  padding: 5px;
  cursor: pointer;
}
.nav-link-pop-up span:hover {
  background-color: #81bd87;
}
#catalog-flag {
  background: none;
  border: none;
  outline: none;
}
svg {
  font-size: 2rem;
}
.active {
  /* border-bottom: 2px solid rgb(10, 156, 5); */
  background-color: #275207;
  border-radius: 5px;
}
.page-links a {
  display: block;
  padding: 7px 0;
  /* padding-left: 40%; */
  color: #000;
  font-weight: bold;
}
.page-links {
  /* min-height: 80vh; */
  /* margin-top: 40%; */
  /* margin-top: 40%; */
  position: fixed;
  bottom: 80px;
  /* left: 28px; */
  z-index: 9999;
  margin-left: 4vw;
}
/* .page-links svg
{
  font-size: 20px;
} */

.page-links button {
  border: none;
  background: none;
  padding-top: 6px;
  display: block !important;
  outline: none;
  font-size: 19px;
  font-weight: bold;

  /* border: solid black 2px; */
  /* padding: -5px; */
  /* margin: 5px; */
}
.page-links button::before {
  content: "•";
  align-items: center;
  padding: 0.9vw;
  font-size: 20px;
}
.button-content-remove::before {
  content: "" !important;
}
/* .page-links button:hover {
  font-weight: 700;
  border-radius: 5px;
  border-color: #198754;
  width: 100%;
  padding: 0px 10px 0px;
  color: white;
  background-color: #198754;
} */
.page-links-hover-button {
  font-weight: 700 !important;
  border-radius: 5px;
  border-color: #275207 !important;
  width: 100%;
  padding: 0px 10px 0px;
  background-color: #275207 !important;
  color: white;
}
.page-links-button-bold {
  font-weight: 700;
}
.page-links-news {
  margin-top: -130px !important;
}
.page-links-home {
}
.page-links-home button {
  font-size: 17px;
  padding-top: 3px;
}
.side-banner-links {
  /* width: 13%; */
}

.arrow-links a,
.arrow-links-1366 a {
  display: block;
  color: #648467;
}
.social-links {
  padding-left: 40%;
}
.arrow-link-text {
  position: relative;
  bottom: -25vh;
  left: -10%;
  transform: rotate(-90deg);
  font-weight: bold;
}
.arrow-links-1366 .arrow-link-text {
  bottom: -18vh;
}
.arrow-line {
  content: "";
  position: absolute;
  width: 3px;
  height: 25%;
  background-color: #648467;
  left: 5.4%;
  top: 58%;
}
.arrow-links-1366 .arrow-line {
  height: 18.5%;
  top: 66%;
}
.fa-greater-than {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  position: relative;
  right: 4.8px;
  bottom: 5%;
  color: #648467;
}
.center-img {
  position: absolute;
  top: 55%;
  left: 61%;
}
.center-img img {
  width: 80%;
  height: auto;
}
.center-text {
  width: 25%;
  position: absolute;
  top: 50%;
  left: 35%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
  opacity: 0.7;
  padding: 2% 5% 2% 5%;
}
.center-text h1 {
  color: #648467;
}
.full-content {
  text-align: justify;
  font-weight: 550;
}
@media (max-width: 1208px) {
  .arrow-link-text {
    bottom: -30vh;
  }
  .arrow-line {
    height: 25%;
  }
}
@media (max-width: 1100px) {
  .center-text {
    width: 30%;
    /* padding: 2% 2% 2% 2%; */
  }
}
@media (max-width: 920px) {
  .center-text {
    padding: 2% 2% 2% 2%;
  }
}
@media (max-width: 800px) {
  .arrow-link-text {
    bottom: -35vh;
  }
  .arrow-line {
    height: 29%;
  }
  .center-img {
    top: 57%;
  }
}
@media (max-width: 992px) {
  .side-banner-links {
    display: none;
  }
}
@media (max-width: 600px) {
  .center-img {
    position: static;
    text-align: center;
  }
  .center-img img {
    width: 60%;
  }
  .center-text {
    position: static;
    width: 90%;
    padding: 1%;
    margin: 20px;
  }
}
.card-video {
  box-shadow: 0 0 0 20px #27ae60;

  border-radius: 15px;

  width: fit-content;

  margin-left: auto;
  margin-right: auto;
}
.youtubeVideo {
  border-radius: 15px;
}
/* .video-youtube {
  position: relative;
  top: -50px;
  left: 25px;
} */
.card-title-heading h2 {
  font-size: 2em;
  text-align: center;
}

.card-img-services img {
  width: 100%;
  height: auto;
}
.text-green {
  color: #27ae60 !important;
}
.bg-green {
  background-color: #27ae60 !important;
}
.video-youtube iframe {
  width: 420px;
  height: 300px;
}
@media only screen and (max-width: 991px) {
  .video-youtube iframe {
    width: 320px;
    height: 240px;
  }
}
@media only screen and (max-width: 360px) {
  .video-youtube iframe {
    width: 250px;
    height: 150px;
  }
}
/* -----------------
--------header Box-----------------
------------ */
.home-slider-main-container {
  /* width: 70vw;
  margin-left: 2.5vw; */

  padding: 0 3% 0 0;
  margin-left: -5%;
  /* padding: 10px; */
}
.home-slide-item {
  padding-top: 17%;
  position: relative;
}
.home-slide-image-item {
  padding-top: 3.5%;
}
.home-slide-image-item img {
  width: 100%;
  padding: 0px 10px;
  margin-top: -10px;
}
@media (max-width: 600px) {
  .home-slide-image-item img {
    margin-top: -218px;
  }
}

.home-slide-text-container {
  /* margin-top: 70px !important; */
  position: absolute;
  width: 40%;
  bottom: 0px !important;
  background-color: #f2fde6;
  /* opacity: 0.7; */
  padding: 10px;
  border-radius: 10px;
}

.home-slide-text-container h3 {
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: bold;
}
.home-slide-item-img {
  height: auto;
  margin-left: 65px;
  margin-top: 35px;
}
.home-slide-title-text {
  color: rgb(0, 0, 0);
  padding: 0px;
  margin: 0px;
}

.home-slide-title-desc {
  color: rgb(0, 0, 0);
  text-align: justify;
  font-weight: bold;
}

#home-slider-banner-cover {
  width: 95%;
  margin: 0 auto;
  height: auto;
}
.home-slide-item {
  /* border: 2px solid red; */
}

@media (max-width: 992px) {
  .home-slider-main-container {
    width: 100%;
    margin-left: 0px;
    margin-top: -10px;
    position: relative;
    padding: 30px;
  }
  .home-slide-item {
    margin-top: 30px;
    padding: 5px;
  }

  #home-slider-banner-cover {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 600px) {
  .home-slide-image-item {
    margin-top: 20px;
  }

  .home-slide-text-container {
    /* margin-top: 70px !important; */
    position: static;
    width: 100%;
    min-height: 310px;
  }
  .home-slide-item-img {
    width: 70%;
    height: auto;
    margin: 10px auto !important;
  }
  .vdi-banner-image {
    width: 90%;
  }
  .home-slide-item {
    background: none !important;
  }
}

/* -----------------
--------product slider-----------------
------------ */
.products {
  padding: 0px;
  /* width: 60vw;
  margin-left: 50px; */
  /* margin-top: 10vh; */
  margin-top: 3%;
  width: 95%;
}

@media (min-width: 1200px) {
  .products {
    padding: 0px;
    margin-top: 3%;
    width: 100%;
    margin-left: -5%;
  }
}

.vision-mission-main-container {
  display: flex;
}
@media (max-width: 600px) {
  .vision-mission-main-container {
    flex-direction: column;
    width: 90%;
    padding: 0px;
    margin: 0px;
  }
  .vision-mission-main-container div {
    flex-basis: 100%;
  }
  .product-slider-container {
    padding: 0px !important;
    margin: 10px 0px !important;
  }
  .products {
    padding: 10px !important;
    width: 90% !important;
    margin-left: 5% !important;
  }
}
.vision-mission-main-container div {
  flex-basis: 33.3%;
}
.products h3 {
  text-align: center;
  color: #648467;
}
.product-slider-container {
  border-radius: 10px;
  padding: 10px;
}
.product-slider-item-vission-mission {
  height: 340px;
}
.product-slider-item {
  background-color: #f2fde6;
  border-radius: 20px;
  padding: 2px;
  /* opacity: 0.7; */
  height: 480px;
}
.product-slider-min-height {
  min-height: 160px !important;
}
.product-slider-container img {
  /* width: 55%; */
  height: 40%;
  margin-left: 25.5% !important;
  /* margin: 0 auto; */
}
.product-slider-content h3 {
  font-size: 20px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.product-slider-content p {
  text-align: justify;
  padding: 10px;
  color: rgb(0, 0, 0);
}
.product-slider-content-btn {
  text-align: center;
}
@media (max-width: 992px) {
  .products {
    padding: 20px;
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1250px) {
  .product-slider-min-height {
    min-height: 95px !important;
  }

  .product-slider-content h3 {
    font-size: 14px;
  }
  .product-slider-item {
    height: 400px;
  }

  .product-slider-content p {
    font-size: 14px;
    padding: 5px;
  }
  .product-slider-content h3 {
    font-size: 18px;
  }
  /* .slick-track .slick-slide > div {
    height: 100%;
  } */
}
@media (max-width: 576px) {
  .products {
    width: 100%;
    margin-left: 0px;
    padding: 30px;
  }

  .product-slider-container {
    padding: 10px;
    margin: 10px;
  }
  .product-slider-item {
    height: 28.5rem;
    margin: 10px;
  }
}

/* -----------------
--------About Section-----------------
------------ */
.about-main-container {
  /* margin-top: 10%; */
  padding: 5px;

  height: 100%;

  height: calc(95vh - 130px) !important;
  max-height: calc(95vh - 130px) !important;
  overflow: scroll;

  display: flex;
  align-items: center;
  padding: 30px 0px;
  position: relative;
}
.about-content-overlay-certificate {
}
.certificate_img_container_wrapper {
  position: absolute;
  left: 0;
  top: -0%;
  height: 100%;
  width: 75%;
  z-index: 99;
}
.certificate_img_container_wrapper img {
  width: 110%;
  max-width: 350px;
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .certificate_img_container_wrapper {
    position: absolute;
    left: 0;
    top: 5%;
    height: 100%;
    width: 70%;
    z-index: 99;
  }
  .certificate_img_container_wrapper img {
    width: 105%;
    max-width: 350px;
  }
}

.about-main-container {
  scrollbar-width: none;
}
.about-main-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.about-video {
  position: relative;
}
.about-video iframe {
  height: 300px;
  width: 350px;
  border-radius: 20px;
}
.about-video-iframe {
  width: 100%;
}
.about-border {
  border: 20px solid #648467;
  border-radius: 20px;
  position: absolute;
  width: 20%;
  height: 270px;
  margin-top: 6%;
  z-index: 0;
}
.about-border-certificate {
  width: 15%;
}
.about-content-container {
  border-right: 5px solid #648467;
  border-radius: 15px;
  text-align: center;
}
.about-content {
  margin-top: 15%;
}
.about-content-certificates {
  margin-top: 5%;
}

.about-content-overlay-certificate {
  /* border: 2px solid red; */
  width: 100%;
  height: 80vh;
  margin-top: -13vh;
}
@media (max-width: 992px) {
  .certificate_img_container_wrapper {
    position: absolute;
    left: -35%;
    top: -5%;
    height: 100%;
    width: 70%;
    z-index: 99;
  }
  .certificate_img_container_wrapper img {
    width: 140%;
    max-width: 140%;
  }

  .about-main-container {
    /* margin-top: 10%; */
    padding: 5px;

    height: 100%;

    height: 100%;
    max-height: 100%;
    overflow: visible;

    display: flex;
    align-items: center;
    padding: 30px 0px;
    position: relative;
  }
}

@media (max-width: 770px) {
  .certificate_img_container_wrapper {
    position: absolute;
    left: 10%;
    top: 5%;
    height: 100%;
    width: 80%;
    z-index: 99;
  }
  .certificate_img_container_wrapper img {
    width: 100%;
    max-width: 100%;
  }
  .about-main-container {
    margin: 50px 0px;
  }
  .about-main-container_certificate {
    overflow: visible;
  }
}
@media (max-width: 576px) {
  .about-content-overlay-certificate {
    margin-top: 2vh;
  }
  .about-content-overlay-certificate img {
    width: 107% !important;
  }

  .certificate_img_container_wrapper {
    position: absolute;
    left: 15%;
    top: 5%;
    height: 100%;
    width: 80%;
    z-index: 99;
  }
}
@media (max-width: 400px) {
  .certificate_img_container_wrapper {
    position: absolute;
    left: 0%;
    top: 5%;
    height: 100%;
    width: 100%;
    z-index: 99;
  }
}

.about-content-overlay-certificate img {
  width: 100%;
  height: 100%;
  margin-top: 0%;
  margin-left: 0%;
}
.about-modal {
  background-color: #81bd87;
  padding: 5px;
  z-index: 5;
  margin-left: -20%;
  margin-right: 40%;
}
.certificate-img-wrapper {
  width: 250px;

  margin-left: -25%;
}
.certificate-img-items img {
  width: 100%;
}

.infra-page {
  margin-top: 5vh !important;

  /* transform: translateX(-15%); */
  margin-left: -10%;
  text-align: center;
}
.infra-machine-item img {
  width: 100%;
}
.infra-machine-container {
  width: 100% !important;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 20px;
  height: fit-content !important;
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  max-height: 70vh;
}
.infra-machine-container p {
  text-align: center;
}
.infra-machine-container h5 {
  text-align: center;
}
.infra-machine-container img {
  width: 80% !important;
  margin: 0 auto;
  margin-left: 10%;
}
@media (max-width: 600px) {
  /* .infra-machine-container div {
    border-bottom: 1px solid black;
  } */
  .infra-machine-container {
    height: fit-content !important;

    max-height: 100%;
  }
}
/* .infra-page p {
  padding: 0px 45px 0px 45px;
} */
.about-modal-overview {
  margin-top: -10%;
  margin-left: -20%;
  /* margin-right: 20%; */
}
.about-content-title {
  color: #000000;
  font-size: 25px;
  text-align: left;
}
.product-content-title {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
}
.about-content-text {
  text-align: justify !important;
}
@media (max-width: 992px) {
  .about-main-container {
    width: 100%;
    margin-bottom: 40px;
  }
  .about-main-container_certificate {
    margin: 50px 0px;
  }
  .about-content-container {
    margin-top: 10px !important;
    border-right: 5px solid #648467;
    border-radius: 15px;
    text-align: center;
    width: 50%;
  }
  .about-video-iframe {
    width: 100%;
  }
  .about-content {
    padding: 10px;
    margin-top: 20px;
  }
  .about-video iframe {
    height: 300px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
  }
  .about-border {
    border: none;
  }
  .certificate-img {
    width: 80%;
    margin: 0 auto;
  }
  .about-content-certificates {
    margin: 10px auto;
  }
  .about-content-container {
    margin: 10px auto;
    width: 100%;
  }
  .infra-page {
    margin-top: 5vh !important;

    margin-left: 0%;
    text-align: center;
  }

  .infra-page p {
    padding: 0px 10px 0px 10px;
  }
}
@media (max-width: 770px) {
  .certificate-img-wrapper {
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .about-video iframe {
    margin-left: 10%;
    height: 300px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
  }
  .about-main-container_certificate {
    margin: 80px 0px;
  }
}
@media (max-width: 350px) {
  .certificate-img-wrapper {
    width: 90%;
  }
}
/* -----------------
--------Side Banner-----------------
------------ */
/* .home-sidebar-links-container {
  margin-left: 5%;
  margin-top: 100%;
}
.home-sidebar-links a {
  display: block;
  padding: 5px;
} */
/* -----------------
--------News Infrastructutre-----------------
------------ */
.news-main {
  position: relative;
  /* margin-top: 10%;
  margin-left: -10%;
  margin-right: 10%; */

  padding: 4% 5% 1% 5%;
}
.news-main h3 {
  text-align: center;
  color: #648467;
}
.news-card-container {
  background-color: #f2fde6;
  opacity: 0.9;
  margin-right: 10px;
  position: relative;
}
.news-card-container img {
  position: absolute;
  width: 22%;
  top: -12px;
  right: 20px;
}
.events-img-styles img {
  width: 20%;
  padding: 10px;
}
.news-card-topic {
  color: rgb(0, 0, 0);
  font-size: 15px;
  padding: 15px;
}
.news-card-title {
  color: rgb(0, 0, 0);
  font-size: 18px;
  padding: 15px;
  font-weight: bold;
  width: 75%;
}
.news-card-desc {
  color: rgb(0, 0, 0);
  font-size: 18px;
  padding: 10px 15px;
}
.news-card-button {
  background-color: #3f5341;
  color: white;
  padding: 8px 15px;
}
.events-main {
  /* margin-top: 8vh !important;
  margin-left: -10%;
  margin-right: 10%; */
  padding: 4% 5% 1% 5%;
}
.events-main h3 {
  text-align: center;
  color: #648467;
}
@media (max-width: 992px) {
  .news-events-main {
    padding: 30px;
  }
  .news-main {
    margin-left: 0px;
    margin-right: 0px;
    padding: 5%;
  }
  .events-main {
    margin-left: 0px;
    margin-right: 0px;
    padding: 5%;
  }
}
@media (min-width: 900px) {
  .infrastructureimageright {
    text-align: right;
  }
  .infrastructureimagerightimg {
    height: 100% !important;
  }
}
@media (max-width: 650px) {
  .news-card-title {
    width: 75%;
  }
  .events-img img {
    width: 23%;
    padding: 0px;
    top: -12px;
    right: 1px;
  }
}
/* -----------------
--------Catologue-----------------
------------ */
.catologue-main-container {
  margin: 30px 10px;
  padding: 2% 6%;
}

.catologue-main-container h3 {
  color: #648467;
}

.catologue-container {
  position: relative;
  padding: 10px;
}

.catologue-image {
  display: block;
  width: 200px;
  /* max-height: 320px; */
  height: auto;
  background-size: contain;
}

.catologue-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #648467;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.1s ease;
  opacity: 0.9;
}

.catologue-container:hover .catologue-overlay {
  width: 100%;
}

.catologue-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* white-space: nowrap; */
}
@media (max-width: 992px) {
  .catologue-main-container {
    padding: 10%;
    width: 100%;
  }
}
/* -----------------
--------Infrastructure-----------------
------------ */

.infrastructure-main-container {
  /* margin-top: 12vh; */
  margin: 12vh 3% 0px 3%;
}
.infrastructure-main-container {
  /* border: 2px solid red; */
}
.infrastructure-main-container h3 {
  color: #648467;
  text-align: center;
  margin: 10px;
}

@media (max-width: 992px) {
  .infrastructure-main-container {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .infrastructure-main-container {
    padding: 20px;
  }
  .infrastructure-main-container img {
    width: 95%;
  }
}

.infrastructure-item h2 {
  margin-top: 2%;
  font-size: 18px;
  text-align: center;
}
.infrastructure-item {
  margin: 0 auto;
}

.infrastructure-item img {
  margin: 0 auto;
  width: 90%;
}

/* single Page */
.infrastructure-page-main-container {
  padding-top: 3%;
}
.infrastructure-page-main-container h3 {
  text-align: center;
}
.infrastructure-page-main-container p {
  text-align: center;
}

.infrastructure-page-main-container .infrastructure-page-item img {
  width: 73%;
  margin: 0 auto;
}
.infrastructure-page-main-container .infrastructure-page-item h2 {
  text-align: center;
  font-size: 20px;
}
.infrastructure-page-main-container .infrastructure-page-item img:hover {
  width: 80%;
  transition: 0.7s;
  margin: 0 auto;
}
.infrastructure-button {
  margin-left: 10px;
  width: 100px !important;
}
.infrastructure-buttons {
  text-align: center;
  margin-top: 5px;
  margin-left: 46%;
  padding: 4px;
}
@media (max-width: 600px) {
  .infrastructure-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 46%;
  }
}

/* -----------------
--------header Box-----------------
------------ */

.header-box-main-container {
  /* background-color: #b93a3e; */
  /* background-color: #648467; */
  position: absolute;
  width: fit-content;
  height: 20vh;
  margin-left: 1.3vw;
  z-index: 1;
  transition: 1s ease-in-out;
  opacity: 0.9;
}

/* .header-box-main-container-company {
  width: 35vw;
  height: 22vh;
}
.header-box-main-container-product {
  width: 25vw;
  height: 23vh;
}
.header-box-main-container-infra {
  width: 32vw;
  height: 24vh;
}
.header-box-main-container-news-event {
  width: 30vw;
  height: 23vh;
}
.header-box-main-container-catalog {
  width: 35vw;
  height: 23vh;
} */
.header-box img {
  width: 14vw;
  height: 25%;
  margin: 0.7vw 4vw;
}
#home-page-border-left {
  position: absolute;
  width: 20px;
  /* border-top: 1.5px solid red; */
  /* border-left: 1.5px solid red; */
  margin-top: 2.4vw;
  /* margin-left: 0.7vw; */
  margin-left: 15px;
  z-index: 9999;
  height: 100vh;
}
#home-page-border-top {
  /* border-top: 1.5px solid red; */
  width: 100vw !important;
  margin-top: 2.4vw;
  margin-left: 19vw;
  position: absolute;
  width: 20px;
}
.home-page-icon-1 {
  position: absolute;
  margin-left: 2vw;
  margin-top: 2.1vw;
}
.home-page-icon-2 {
  position: absolute;
  margin-left: 19.5vw;
  margin-top: 2.1vw;
}
.header-box-bottom-img {
  /* height: 150px;
  width: 200px; */
  width: 12vw;

  /* padding: 0.6vw; */
  margin: 0.7vw 4vw;
  margin-top: 36px;
  background: #f2fde6;
}
@media (max-width: 576px) {
  .header-box-main-container {
    width: 100%;
    padding-left: 20%;
    padding-top: 4%;
    height: auto;
  }
  .header-box img {
    width: 70% !important;
  }
}
@media (max-width: 992px) {
  .header-box-main-container {
    width: 80%;
    /* margin-top: 80px; */
    padding-left: 24%;
    height: auto;
  }
  #home-page-border-left {
    border: none;
  }
  #home-page-border-top {
    border: none;
  }
  .header-box-bottom-img {
    display: none;
    /* width: 50%;
    position:absolute;
    top:300px; */
  }
  .home-page-icon-2 {
    display: none;
  }
  .home-page-icon-1 {
    display: none;
  }
  .header-box img {
    width: 50%;
  }
}

/* .header-box-main-container {
  border: 2px solid red;
}
.navbar-main-container {
  border: 2px solid yellow;
} */
/* ------------------
------ */
thead.product-table-column tr :nth-child(1) {
  /* width: 250px; */
  /* min-width: 200px; */
  height: -10px;
}

.product-table-column tr th {
  font-weight: 500 !important;
}
.product-table-column tr td {
  border: 1px solid #000000;
}
/* .product-table-column tr {
  width: 250px;
  min-width: 20px !important;
  height: 15px;
} */
.slick-prev:before,
.slick-next:before {
  color: #000000 !important;
}
@media (max-width: 992px) {
  .mobile-background-color {
    height: 90px;
    /* background-color: #6c8a6f; */
    background-color: #275207;
    padding: 2px;
    opacity: 0.9;
    z-index: -1;
  }
  .navbar-nav {
    margin-top: 30px;
    /* background-color: #275207; */
  }
  .nav-item {
    padding-left: 15px;
  }
}
.nav-item a.active {
  color: white !important;
}

.breadcrubms {
  cursor: pointer;
  font-size: 0.8em;
}
.breadcrubms:hover {
  text-decoration: underline;
}
span.breadcrubms + span.breadcrubms:before {
  padding: 8px;
  color: black;
  content: "-->\00a0";
  text-decoration: none !important;
}
/* //blog single Item */
.blog-container {
  margin-top: 4% !important;
}
.blog-row {
  display: flex;
}
.blog-row .blogleft {
  width: 30%;
}
.blog-row .blogright {
  width: 70%;
}
.margib-left-10 {
  margin-left: 100px;
}
.customTitleh1 h2 {
  color: #000000;
  margin-top: 3% !important;
  margin-left: 30%;
}
.contact-us-title h2 {
  margin-top: 2%;
  margin-left: 37%;
}
.contact-us-title h1 {
  margin-top: 2%;
  margin-left: 37%;
  font-size: 2rem;
}
.infrastructure-page-title h1 {
  font-size: 25px;
  text-align: center;
  margin: 2% 0%;
}
.home-slider-main-container {
  background-repeat: no-repeat;
  background-size: 100%;
}
.home-slide-text-container {
}
.home-slide-item {
  /* background-image: url("https://ik.imagekit.io/0rsvz9vomoe/Test_image/machine1__3__4koo3BqFU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652168221796"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-left: -5%; */
}

@media (min-width: 1500px) {
  .home-slide-item {
    margin-top: 5%;
  }
  .home-slide-item {
    /* border: 2px solid red; */
    margin-left: 1.5%;
  }
}

/* .side-banner-svg
{
 font-size: 20px; 
 color: green;
 position:relative ;
 left: 5%;
} */
.findyourtool {
  background-color: red;
  border-radius: 5px;
  margin-left: 15px;
}
.findyourtool :hover {
  background-color: #275207;
  box-shadow: 5px 5px 3em #96f14f;
}

.findyourtool a {
  color: white !important;
  font-weight: bold !important;
}
.findyourtool a :hover {
  color: white !important;
}

.onlinestore {
  background-color: #275207;
  margin-left: 15px;
}
.onlinestore a {
  color: white !important;
}
@media (max-width: 600px) {
  .findyourtool {
    position: absolute;
    top: 100px;
    left: 160px;
    width: 200px;
    text-align: center;
  }
  .onlinestore {
    position: absolute;
    top: 150px;
    left: 200px;
    border-radius: 5px;
    width: 100px;
    text-align: center;
  }
}

/* //new styles  */

.home_products {
  max-height: calc(100vh - 140px);
  overflow: auto;
  /* scrollbar-width: none; */
}

@media (max-width: 1000px) {
  .home_products {
    max-height: 100%;
    scrollbar-width: none;
    overflow: visible;
  }
}

.vision_mission_company {
  max-height: calc(100vh - 140px);
}

.vision_mission_company .product-slider-item {
  height: 100%;
}
@media (max-width: 1000px) {
  .vision_mission_company {
    max-height: 100%;
  }
}

@media (min-width: 1400px) {
  .home-container {
    height: calc(100vh - 160px);
  }
  .home-slider-main-container {
    height: 88%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4% auto 0 auto;
  }
  .home-slider-main-container .slick-slider {
    width: 100%;
  }
  .home-slide-image-item img {
    padding: 0;
  }
}

.events-main-home .slick-track {
  display: flex;
}

.events-main-home .slick-track > div {
  height: auto;
}
.events-main-home .slick-track > div > div {
  height: 100%;
}
.events-main-home .news-item {
  height: 100%;
}
.events-main-home .news-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
