.blogSingleItemContainer {
  background-color: #f2fde6;
  width: 97%;
  margin: 0 auto;
  margin-left: -2% !important;
}
@media (max-width: 576px) {
  h2 {
    margin-top: 5% !important;
  }
  .blogSingleItemContainer {
    background-color: #f2fde6;
    width: 95%;
    margin-left: 2.5% !important;
  }
  .blogSingleItem {
    padding: 5px;
  }
}
.blogSingleItem {
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  height: 70vh;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}
@media (max-width: 992px) {
  .blogSingleItem {
    height: 90vh;
    max-height: 100%;
  }
}
@media (max-width: 600px) {
  .blogSingleItem {
    height: 100%;
    overflow: none;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
  }
}
