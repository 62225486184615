.DictionaryContainer {
  /* background: #81bd87; */
  width: 97% !important;
  padding: 20px;
  border-radius: 4px;
  background: #f2fde6;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin: auto auto;
  margin-left: -3% !important;
  /* margin-top: -5% !important; */
  padding-bottom: 1px !important;
  /* background-color: #dff6c8; */
  display: flex;
  scrollbar-width: none;

  flex-direction: column;

  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  max-height: calc(100vh - 200px);
  overflow: scroll;
  /* height: 75vh !important; */
  /* max-height: 500px;
  min-height: 550px; */
  /* border: 2px solid red; */
}

.DictionaryContainer h1 {
  color: #648467;
  text-align: center;
  /* padding-top: 10px; */
  /* cursor: pointer; */
}
.contact-us-title h2 {
}
.CareersContent {
  /* border: 2px solid; */
  display: flex;
  flex-direction: row;

  /* justify-content: space-between;
  margin-left: 30px; */
}
.CareersForm {
  flex: 2;
  margin-top: -7px !important;
}
.CareersForm label {
  font-size: 13px;
}
.CareersInfo {
  flex: 3;
}
.CareersInfo {
  /* border: 2px solid red; */
  height: 100%;

  font-size: 13px;
  color: #333;
  text-align: center;
  /* padding: 20px 40px; */
  margin-left: -5%;
  text-align: left;
}

.CareersInfo h4 {
  margin-top: 10px;
}

.CareersInfo h6 {
  color: rgb(0, 0, 0);
  width: 100%;
}
.CareersInfo svg {
  padding-left: 10px;
}
.CareersInfo iframe {
  width: 100%;
  height: 200px;
}
@media (max-width: 1208px) {
  .CareersInfo iframe {
    width: 100%;
    height: 150px !important;
  }
}
.CareersForm {
  /* border: 2px solid red; */
  height: 100%;
  width: 50%;
  margin-top: 10px;
}

.CareersForm form div {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin: auto;
  margin-top: 5px;
}

.CareersForm form div input {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
}

.CareersForm form div button {
  border-radius: 5px;
  width: 50%;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 5px;
  border: none;
}

.CareersForm form div button:hover {
  color: white;
  background-color: #135819;
}
@media (min-width: 1400px) {
  .DictionaryContainer {
    height: calc(95vh - 200px) !important;
    max-height: calc(95vh - 200px) !important;
  }
}
@media (max-width: 992px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    max-height: 100%;
    /* margin-left: -10% !important; */
    margin-top: 4% !important;
    max-height: 100%;
    overflow: visible;
  }
  .CareersContent {
    display: flex;
    flex-direction: column;
  }
  .CareersForm {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    margin: 10px 0px;
  }
  .CareersForm form div {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    margin-top: 5px;
  }
  .CareersInfo {
    height: 100%;
    width: 100%;
    font-size: 13px;
    padding: 20px 5px;
    margin-left: 0%;
    text-align: center;
  }

  .CareersInfo h6 {
    color: rgb(122, 122, 122);
    width: 100%;
  }
  .CareersInfo iframe {
    width: 100%;
    height: 250px;
  }
}
@media (max-width: 600px) {
  .DictionaryContainer {
    /* background: #81bd87; */
    width: 100% !important;
    margin-left: 0% !important;
  }
}

.CarreAddress {
  display: flex;
  padding-top: 1%;
}

.CarreAddress div {
  padding: 1% 2%;
  flex-basis: 50%;
}
.CarreAddress h2 {
  font-size: 22px;
  text-align: left;
  margin-left: 0%;
  font-weight: 700;
  margin-bottom: 10px;
}
.CarreAddress h5 {
  font-size: 19px;
}
.CarreAddress h4 {
  font-size: 20px;
}
.CarreAddress h6 {
  /* font-size: 20px; */
  line-height: 27px;
}
@media (min-width: 992px) {
  .AddressIndia {
    border-right: 3px solid green;
  }
}
@media (max-width: 576px) {
  .CarreAddress {
    display: flex;
    flex-direction: column;
  }
}
